import * as React from "react";

const FacebookLogo: React.FC<{ cls?: string }> = ({ cls }) => (
  <svg
    className={cls}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#1371C8"
    width="24px"
    height="24px"
  >
    <path d="M0 0h24v24H0V0z" fill="none"></path>
    <path d="M21,12 C21,7.02943359 16.9705664,3 12,3 C7.02943359,3 3,7.02943359 3,12 C3,16.4921602 6.29116992,20.2154883 10.59375,20.8906641 L10.59375,14.6015625 L8.30859375,14.6015625 L8.30859375,12 L10.59375,12 L10.59375,10.0171875 C10.59375,7.7615625 11.9373867,6.515625 13.9931836,6.515625 C14.9778574,6.515625 16.0078125,6.69140625 16.0078125,6.69140625 L16.0078125,8.90625 L14.8729336,8.90625 C13.7549121,8.90625 13.40625,9.60000586 13.40625,10.3117441 L13.40625,12 L15.9023438,12 L15.5033203,14.6015625 L13.40625,14.6015625 L13.40625,20.8906641 C17.7088301,20.2154883 21,16.4921602 21,12"></path>
  </svg>
);

export default FacebookLogo;
