import { graphql, useStaticQuery } from "gatsby";

export type VideosSidebarItem = {
  name: string;
};

export const VideoContentSidebarQuery = (): { list: VideosSidebarItem[] } => {
  const {
    allWp: { nodes },
  } = useStaticQuery(graphql`
    {
      allWp {
        nodes {
          graphqlOptions {
            videoContentSidebarO {
              videosSidebarList {
                name
              }
            }
          }
        }
      }
    }
  `);
  const videosSidebarList =
    nodes[0]?.graphqlOptions?.videoContentSidebarO?.videosSidebarList;
  return {
    list: videosSidebarList || [],
  };
};
