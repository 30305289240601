import React from "react";
import FacebookLogo from "../../components/SVGIcons/SocialMediaLogos/FacebookLogo";
import LinkedinLogo from "../../components/SVGIcons/SocialMediaLogos/LinkedinLogo";
import TwitterLogo from "../../components/SVGIcons/SocialMediaLogos/TwitterLogo";
import BlackCheckmark from "../../components/SVGIcons/BlackCheckmark/BlackCheckmark";
import WistiaVideos from "../../components/Videos/WistiaVideos/WistiaVideos";
import { ResourcesCItemType } from "../../assets/Types/AcfGroupTypes/ResourcesPg.type";
import { VideoContentSidebarQuery } from "../../assets/StaticQueries/VideoContentSidebar.query";
import PrimaryButtonWithArrow from "../../components/Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import ModalTrigger from "../../components/Buttons/ModalTrigger";

import styles from "./ResourcesLayoutTwoContainer.module.scss";

type ResourcesLayoutTwoContainerProps = {
  ResourcesLayoutData: ResourcesCItemType;
  uri?: string;
  title?: string;
};

const ResourcesLayoutTwoContainer: React.FC<ResourcesLayoutTwoContainerProps> =
  ({ ResourcesLayoutData, uri, title }) => {
    const { list: videosSidebarList } = VideoContentSidebarQuery();
    return (
      <>
        <section
          className={`pad-bottom--large full-width ${styles.classesGrid}`}
        >
          <div className={styles.mainContent}>
            <div>
              <WistiaVideos videoId={ResourcesLayoutData.wistiaId} autoplay />
              <div className={`show-for-small ${styles.videoSections}`}>
                <PrimaryButtonWithArrow
                  className={styles.buttonStyle}
                  buttonLink={"/sign-up"}
                  buttonText={"Start My Free Trial"}
                  data-click-type="sign_up_cta"
                  data-click-text="Start for free"
                  data-click-location="Resource-Layout"
                />
                <p>Free 30-day trial, access all this &amp; more:</p>
                {videosSidebarList &&
                  videosSidebarList.map((feature, id) => (
                    <div
                      className={styles.videoRow}
                      key={`features-small-${id}`}
                    >
                      <BlackCheckmark cls={styles.icon} />
                      {feature.name}
                    </div>
                  ))}
              </div>
              <div className={`show-for-small ${styles.resourceSignupCta}`}>
                <h3>Want to know about other great webinars?</h3>
                <ModalTrigger
                  className={styles.btnFollow}
                  modalTrigger={"sitewide-generic-resource"}
                >
                  Join Our List
                </ModalTrigger>
              </div>
            </div>
            <div
              className={styles.videoContent}
              dangerouslySetInnerHTML={{
                __html: ResourcesLayoutData.videoContent,
              }}
            />
            <section className={styles.socialSharing}>
              <a
                href={`https://facebook.com/sharer/sharer.php?u=https://www.simplepractice.com/private-practice${uri}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookLogo cls={styles.socialSharingIcon} />
              </a>
              <a
                href={`https://twitter.com/intent/tweet/?text=${title}&amp;url=https://www.simplepractice.com${uri}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterLogo cls={styles.socialSharingIcon} />
              </a>

              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.simplepractice.com${uri}&amp;title=${title}&amp;summary=${title}&amp;source=https://www.simplepractice.com${uri}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedinLogo cls={styles.socialSharingIcon} />
              </a>
            </section>
          </div>
          <div data-testid="Div:sideContent" className={styles.sideContent}>
            <div className={`hide-for-small ${styles.videoSections}`}>
              <PrimaryButtonWithArrow
                className={styles.buttonStyle}
                buttonLink={"/sign-up"}
                buttonText={"Start My Free Trial"}
                data-click-type="sign_up_cta"
                data-click-text="Start for free"
                data-click-location="Resource-Layout"
              />

              <p>Free 30-day trial, access all this &amp; more:</p>

              {videosSidebarList &&
                videosSidebarList.map((feature, id) => (
                  <div className={styles.videoRow} key={`features-${id}`}>
                    <BlackCheckmark cls={styles.icon} />
                    {feature.name}
                  </div>
                ))}
            </div>
            <div className={`hide-for-small ${styles.resourceSignupCta}`}>
              <h3>Want to know about other great webinars?</h3>
              <ModalTrigger
                className={styles.btnFollow}
                modalTrigger={"sitewide-generic-resource"}
              >
                Join Our List
              </ModalTrigger>
            </div>
          </div>
        </section>
      </>
    );
  };

export default ResourcesLayoutTwoContainer;
