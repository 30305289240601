import * as React from "react";
import { ResourcesSliderLayoutType } from "../../assets/Types/AcfGroupTypes/ResourcesSliderPLayout.type";
import ResourcesLayoutSlider from "../../components/Sliders/ResourcesLayoutSlider/ResourcesLayoutSlider";

import styles from "./ResourcesSliderLayoutContainer.module.scss";

const ResourcesSliderLayoutContainer: React.FC<ResourcesSliderLayoutType> = ({
  resourcesCategories,
}) => {
  return (
    <section className={styles.container}>
      <div className="full-width">
        <h3 data-testid="h3:sliderTitleLayout" className={styles.sliderTitle}>
          Build the best practice you can.
        </h3>
        <p style={{ paddingBottom: "64px" }} className={"primary--grey-text"}>
          Our resources to help you & your practice take the next step.
        </p>
      </div>
      <ResourcesLayoutSlider resourceList={resourcesCategories.nodes[0]} />
    </section>
  );
};

export default ResourcesSliderLayoutContainer;
