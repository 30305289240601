import * as React from "react";
import LeftArrow from "../../SVGIcons/LeftArrow/LeftArrow";

import styles from "./ResourcesLayoutTwoBanner.module.scss";

const ResourcesLayoutTwoBanner: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <div className={`pad-top--large-header ${styles.mainContainer}`}>
      <div className="full-width">
        <a className={styles.arrowBackContainer} href="/resources/">
          <span className={styles.arrowIcon}>
            <LeftArrow cls={styles.icon} />
          </span>
          Free Resources
        </a>
        <h1 data-testid="H1:title" className={styles.heroSingleTitle}>
          {title}
        </h1>
      </div>
    </div>
  );
};

export default ResourcesLayoutTwoBanner;
