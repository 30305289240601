import * as React from "react";
import { ResourceSliderItemLayoutType } from "../../../assets/Types/AcfGroupTypes/ResourcesSliderPLayout.type";
import ResourceSliderCard from "../../Cards/ResourceSliderCard/ResourceSliderCard";
import Slider from "react-slick";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";
import LeftArrow from "../../SVGIcons/LeftArrow/LeftArrow";
import "./ResourcesLayoutSlider.scss";
import styles from "./ResourcesLayoutSlider.module.scss";

interface ArrowProps {
  isPrevious?: boolean;
  className?: string;
  onClick?: () => void;
}

const ResourcesLayoutSlider: React.FC<{
  resourceList: ResourceSliderItemLayoutType;
}> = ({ resourceList }) => {
  const cardList =
    resourceList &&
    resourceList.contentNodes.nodes.map((card, index) => (
      <ResourceSliderCard
        key={index}
        category={resourceList.name}
        card={card}
      />
    ));

  const Arrow = ({ onClick, className, isPrevious = false }: ArrowProps) => (
    <button className={`${styles.arrow} ${className}`} onClick={onClick}>
      {isPrevious ? <LeftArrow /> : <RightArrow />}
    </button>
  );

  const settings = {
    draggable: true,
    infinite: false,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          variableWidth: true,
          draggable: true,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          variableWidth: true,
          draggable: true,
          infinite: true,
        },
      },
    ],
    nextArrow: <Arrow />,
    prevArrow: <Arrow isPrevious />,
  };

  return (
    <div
      data-testid="Div:ResourcesSlider"
      className={`${styles.sliderContainer} resources__slider`}
    >
      <Slider {...settings}>{cardList}</Slider>
    </div>
  );
};

export default ResourcesLayoutSlider;
