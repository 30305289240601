import * as React from "react";

const TwitterLogo: React.FC<{ cls?: string }> = ({ cls }) => (
  <svg
    className={cls}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#1371C8"
    width="24px"
    height="24px"
  >
    <path d="M0 0h24v24H0V0z" fill="none"></path>
    <path d="M8.66064,19.6260577 C15.45312,19.6260577 19.16832,13.9985377 19.16832,9.1183777 C19.16832,8.9585377 19.16832,8.7994177 19.15752,8.6410177 C19.8802786,8.11823352 20.5041759,7.47093087 21,6.7294177 C20.3259929,7.02807498 19.6109971,7.22393433 18.87888,7.3104577 C19.6498157,6.84892478 20.2268089,6.12301353 20.50248,5.2678177 C19.7775534,5.69798553 18.9844652,6.00114636 18.15744,6.1642177 C17.0126996,4.94698158 15.1937179,4.64905876 13.7204755,5.43750725 C12.247233,6.22595574 11.486118,7.90469223 11.86392,9.5323777 C8.89456493,9.38351737 6.12802256,7.98101046 4.2528,5.6738977 C3.27260838,7.36132191 3.77327128,9.52003653 5.39616,10.6037377 C4.80845366,10.5863191 4.23356115,10.4277792 3.72,10.1414977 C3.72,10.1566177 3.72,10.1724577 3.72,10.1882977 C3.72048106,11.9462446 4.95966107,13.460363 6.6828,13.8084577 C6.13910611,13.9567345 5.56865802,13.9784096 5.01528,13.8718177 C5.49908251,15.3762021 6.88553368,16.4067821 8.46552,16.4364577 C7.15780923,17.4642087 5.54236285,18.0221337 3.87912,18.0204577 C3.58529057,18.0198936 3.29174693,18.0021031 3,17.9671777 C4.68885788,19.0509768 6.65393765,19.6258539 8.66064,19.6231777"></path>
  </svg>
);

export default TwitterLogo;
