import * as React from "react";
import { Link } from "gatsby";
import { ResourceSliderCardLayoutType } from "../../../assets/Types/AcfGroupTypes/ResourcesSliderPLayout.type";

import styles from "./ResourceSliderCard.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ResourceSliderCard: React.FC<{
  card: ResourceSliderCardLayoutType;
  category: string;
}> = ({
  card: {
    resourcesC: {
      resourceCustomTitle,
      resourcesCardsImage,
      resourcesCardsLink,
      resourceType,
    },
    title,
    uri,
  },
  category,
}) => {
  const hasPost = resourceType !== "series" && resourceType !== "calculator";

  const image = getImage(resourcesCardsImage?.localFile);

  return (
    <Link
      className={styles.card}
      to={hasPost ? uri : resourcesCardsLink!}
      data-testid="Div:Card"
    >
      <div>
        {image ? (
          <GatsbyImage image={image} alt={resourcesCardsImage?.altText || ""} />
        ) : (
          <img
            src={resourcesCardsImage?.mediaItemUrl}
            alt={resourcesCardsImage?.altText || ""}
            className={styles.imageCard}
          />
        )}
      </div>
      <div className={styles.resourcesText}>
        <span
          className={`badge--text primary--blue-text ${styles.resourcesTitle}`}
        >
          {category}
        </span>
        <h4>{resourceCustomTitle ? resourceCustomTitle : title}</h4>
      </div>
    </Link>
  );
};

export default ResourceSliderCard;
